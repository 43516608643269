.header {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
  border-radius: 0px 0px 25px 25px;
  position: fixed;
  z-index: 9999;
  left: 0;
  width: 100%;
}

.logo {
  text-align: center;
}
.logo {
  position: absolute;
  text-align: center;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.logo img {
  width: 100%;
  height: 100%;
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-top: -40px;
  margin-bottom: 0;
}

.schedule__link {
  margin-right: 20px;
}
.contact__title, .schedule__link {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}
.menu a {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  transition: 0.3s;
}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.nav__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart__icon i,
.user i,
.mobile__menu i {
  font-size: 1.3rem;
  color: #212245;
  cursor: pointer;
}



.cart__icon {
  position: relative;
  margin: 0px 20px 0px 20px;
}

.cart__badge {
  position: absolute;
  top: 0px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

@media only screen and (max-width: 992px) {
  .schedule {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .logo img {
    width: calc(100% - 30%);
  }
  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 0.9rem;
  }

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
  }

  .logo img {
    margin-top: -70px !important;
  }
  .logo h5 {
    margin-top: -55px;
  }
  .nav__right  {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .schedule {
    display: none;
  }
  .header__head-link {
    display: flex;
    align-items: center;
  }
  .main {
    width: 100% !important;
  }
}
