.hero__content {
  padding-top: 70px;
}

.hero__title {
  font-size: 2.5rem;
  line-height: 55px;
}

.hero__title span {
  color: #df2020;
}


.hero__btns button {
  border: none;
  outline: none;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}
.main {
  width: 500px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}

.main__desc {
  color: #f5f5f5;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.main__title {
  color: #e21d23;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
}

.menu__desc {
  color: white;
  margin-bottom: 40px;
}

.main__link {
  color: #fff !important;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 34px;
  background: #e31e23;
  border: 1px solid #e21d23;
  padding: 15px 75px;
  margin-top: 25px;
}/*# sourceMappingURL=main.css.map */

.order__btn {
  background: #df2020;
  color: #fff;
  transition: 0.3s;
}

.order__btn:hover {
  background: #212245;
}
.all__foods-btn {
  background: transparent;
  border: 1px solid #df2020 !important;
}

.all__foods-btn a {
  color: #df2020;
}

.hero__service p {
  color: #212245;
  font-weight: 600;
  font-size: 0.9rem;
}

.shipping__icon i {
  padding: 5px;
  background: #df2020;
  color: #fff;
  border-radius: 50%;
  font-weight: 500 !important;
}
@media only screen and (max-width: 768px) {
  .main {
    height: 100vh !important;
  }
}