/* @import url(../../node_modules/bootstrap/scss/_modal.scss); */

.checkout__bill {
  background: #ffffff;
  padding: 30px;
}
.checkout {
  background: white;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
.checkout__btn {
  width: 100%;
  padding: 20px 0;
  margin-top: 25px;
}
.form__group {
  display: flex;
  justify-content: space-around;
}
.checkout__total {
  border-top: 1px solid #ddd;
  padding-top: 30px;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  background: transparent !important;
  border: none !important;
}
.modal1 {
  background: #17161b;
  position: relative;
  text-align: center;
  color: #ffffff;
  padding: 25px;
  border-radius: 25px;
}
.modal-img-logo {
  width: 64px;
  position: absolute;
  right: -25px;
  top: -25px;
}
.modal-head {
  margin: 25px 0px 10px 0px;
}
.modal-footer {
  justify-content: center !important;
  border: none !important;
}
.btn-secondary {
  background: red !important;
  color: white;
  border: none !important;
  margin-top: 25px !important;
}
.btn-secondary a:hover {
 color: white;
}
@media only screen and (max-width: 768px) {
  .checkout__bill h6 {
    font-size: 0.9rem;
  }
  .checkout__bill {
    margin-top: 40px;
  }
}
