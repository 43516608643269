
.footer__box-place a:hover {
  color: #df2020;
}
.footer__box-link a:hover {
  color: #df2020;
}

.footer__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 15px 20px 15px 20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.footer__box-logo {
  position: absolute;
  text-align: center;
  bottom: -65px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer__box {
  position: relative;
}
.footer__box-link {
  text-align: center;
}

.ghostcompany {
  background: black;
  color: white;
  text-align: center;
}
.ghostcompany a:hover {
  color: red;
}

@media only screen and (max-width: 768px) {
  .footer__box-logo {
    bottom: -45px;
  }
  .footer__box-logo img {
    width: calc(100% - 30%);
  }
  .footer__box-link, .footer__box-place-text {
    font-size: 12px;
  }
}