/* google fonts */

@import url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web&display=swap");

/* reset default style */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* base style */

body {
  background-image: url(./assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {

} */

a {
  text-decoration: none;
  color: unset;
}
p {
  margin-bottom: 0;
}

/* h1,
h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #212245;
} */
/* 
section {
  padding: 60px 0px;
} */

/* @media only screen and (max-width: 768px) {
  section {
    padding: 40px 0px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }
} */
