.about__box p {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 15px;
}
.about__box {
    background: white;
    padding: 20px;
    margin-bottom: 100px;
}
.about__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 40px;
}
.about__contact a:hover {
    color: #e21d23;
}
.about__contact-link {
    font-family: Montserrat;
    font-size: 24px;
    margin-bottom: 15px;
}
.about__contact-link img {
    width: 32px;
    height: 32px;
}

@media only screen and (max-width: 768px) {
    .about__box p {
        font-size: 16px;
    }
    .about__contact-link {
        font-size: 18px;
    }
}