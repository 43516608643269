.delivery {
  background: white;
  padding: 50px 20px;
  border-radius: 20px;
  margin-bottom: 150px;
}
.delivery__box {
  display: flex;
  justify-content: space-around;
}

.delivery__box-img {
  width: 50%;
  object-fit: contain;
}
.delivery__box-link {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  margin-left: 25px;
}
.delivery__box-link-title {
  color: #000;

  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 25px;
}
.delivery__box-link-icon {
  color: #000;
  margin-bottom: 20px;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.8px;
}
.delivery__box-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delivery__box-link-icon img {
  margin-right: 10px;
}
.delivery__box-link-icon:hover {
  color: #e21d23;
}
.delivery__box-link-icon img {
  width: 24px;
}
.delivery__box-button {
  color: #ffffff;

  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.8px;
  padding: 25px 0px;
  width: 100%;
  border-radius: 21px;
  background: #17161b;
}
.delivery__box-button:hover {
  color: red;
}
.delivery__box-link-block {
  display: flex;
  flex-direction: column;
}

.delivery__info-block {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.delivery__info-block-card {
  margin: 40px 40px 40px 40px;
  width: 500px;
  height: 220px;
  border-radius: 39.66px;
  background: #17161b;
  padding: 25px 75px 25px 35px;
  position: relative;
}
.delivery__info-block-text {
  color: #e21d23;

  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 15px;
}
.delivery__info-block-title {
  color: #fff;
  letter-spacing: 1.5px;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.8px;
  margin-bottom: 15px;
}

.delivery__info-block-desc {
  color: #fff;
  letter-spacing: 1.5px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.delivery__info-block-img-card {
  margin-bottom: 15px;
}

.delivery__info-block-img {
  position: absolute;
  right: -30px;
  top: -30px;
  width: 120px;
}
@media only screen and (max-width: 768px) {
  .delivery__box {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .delivery__box img {
    width: 100%;
  }
  .delivery__info-block-img {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 80px;
  }

  .delivery__box-link-title {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .delivery__box-link-icon {
    font-size: 14px;
  }
  .delivery__box-link-icon img {
    width: 24px !important;
  }
  .delivery__box-link-block {
    flex-direction: row;
    justify-content: space-between;
  }
  .delivery__info-block-card {
    margin: 15px 15px;
    padding: 25px;
  }
  .delivery__info-block-text {
    font-size: 24px;
  }
  .delivery__info-block-img-card {
    width: 48px;
  }
  .delivery__info-block-title {
    font-size: 14px;
  }
}